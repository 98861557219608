<template>
  <!-- 创建房间 -->

  <div class="creat_bg">
    <boxDetail :boxDetail="boxDetail"></boxDetail>
    <div class="animate__animated animate__fadeInDown" ref="slider">
      <div class="creat_close" @click="
        handleclose();
      playAudio();
      ">
        <svg t="1708678106448" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="2989">
          <path
            d="M898.844444 830.577778c22.755556 17.066667 22.755556 51.2 0 73.955555s-51.2 22.755556-73.955555 0L512 591.644444l-312.888889 312.888889c-11.377778 11.377778-22.755556 17.066667-39.822222 17.066667-11.377778 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-22.755556-51.2 0-73.955555l312.888889-312.888889-307.2-318.577778c-11.377778-5.688889-17.066667-22.755556-17.066667-34.133333 0-11.377778 5.688889-28.444444 17.066667-34.133334 22.755556-22.755556 51.2-22.755556 73.955555 0L512 443.733333l312.888889-312.888889c22.755556-22.755556 51.2-22.755556 73.955555 0s22.755556 51.2 0 73.955556l-312.888888 312.888889 312.888888 312.888889z"
            fill="#727477" p-id="2990" />
        </svg>
      </div>
      <div class="creat_number">
        <div style="width: 57%" class="fjrs">房间人数</div>
        <div class="creat_number_select space_around">
          <div class="select_btn center" @click="
            selectpeople(2);
          playAudio();
          " :class="objj.playerNumber == 2 ? 'active' : ''">
            2人
          </div>
          <div class="select_btn center" @click="
            selectpeople(3);
          playAudio();
          " :class="objj.playerNumber == 3 ? 'active' : ''">
            3人
          </div>
          <div class="select_btn center" @click="
            selectpeople(4);
          playAudio();
          " :class="objj.playerNumber == 4 ? 'active' : ''">
            4人
          </div>
        </div>
      </div>
      <div class="creat_type">
        <div class="space_between">
          <div class="create_type_home">
            房间类别（模式）
            <div class="create_type_model space_around">
              <div class="select_btn center" @click="
                selectmodel(0);
              playAudio();
              " :class="objj.model == 0 ? 'active' : ''">
                欧皇
              </div>
              <div class="select_btn center" @click="
                selectmodel(1);
              playAudio();
              " :class="objj.model == 1 ? 'active' : ''">
                非酋
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- <div class="creat_box left">
        <div
          v-for="(item, index) in obj.boxData"
          :key="index"
          class="center_img"
        >
          <div v-if="item.boxNum > 0">
            <div>
              <img :src="item.boxImg01" alt width="100%" />
            </div>
            <div class="center_number center">{{ item.boxNum }}</div>
          </div>
        </div>
      </div> -->

      <div class="creat_msg space_around">
        <div>
          回合数
          <div>
            <span style="color: #25f484">{{ totalround }}</span>/12
          </div>
        </div>
        <div>
          盲盒种类
          <div>
            <span style="color: #25f484">{{ obj.boxData.length }}</span>/6
          </div>
        </div>
        <div>
          盲盒价值
          <div style="color: #25f484">{{ totalmoney }}</div>
        </div>
      </div>
      <div class="creat_boxlist" @scroll="handleScroll">
        <div class="boxlist_box" :class="item.boxNum > 0 ? 'active' : ''" v-for="(item, index) in boxList" :key="index">
          <div class="boxlist_img" @click="getBoxDetail(item.boxId)">
            <img :src="item.boxImg01|fullPath" alt width="100%" />
          </div>
          <div class="boxlist_number">
            <plusreduce style="width: 100%; height: 100%" :class="item.boxNum > 0 ? 'actives' : ''"></plusreduce>
            <div class="space_between boxlist_input">
              <div @click="
                handleDel(item, index);
              playAudio();
              " class="jian"></div>
              <div class="input_number">{{ item.boxNum }}</div>
              <div @click="
                handlePush(item);
              playAudio();
              " class="jia"></div>
            </div>
          </div>
          <div class="boxlist_money money-money">
            <money class="money"></money>
            {{ item.price }}
          </div>
          <div class="textover-f center_boxboxlist_name">
            {{ item.boxName }}
          </div>
        </div>
      </div>
      <div class="cerate_btn center" @click="
        handleCreat();
      playAudio();
      ">
        创建
      </div>
    </div>
  </div>
</template>

<script>
import boxDetail from "./boxDetail.vue";
import {
  createFight,
  getUserInfo,
  getBoxList,
  /*   getBoxTypeList, */
} from "@/api/index";
export default {
  components: {
    boxDetail,
  },
  data() {
    return {
      show_create_room: false,
      istypeshow: false,
      boxtype: "",
      selectedmodel: 0,
      people: 2,
      isCreat: false,
      model: 0,
      boxList: [],
      changeboxList: [],
      boxTypeList: [],
      money: 0,
      obj: {
        boxData: [],
        createNum: 0,
      },
      boxobj: {},
      objj: {
        /*    boxIdAndNumber:null, */
        
boxIdAndNumber: {53: 2, 54: 2, 55: 1, 56: 1},
        model: 0, //模式
        playerNumber: 2, //人数
      },
      boxPage: {
        page: 1,
        size: 10,
        gameMouldId: 2,
        boxTypeId: "",
        orderByFie: 2,
      },
      typePage: {
        page: 1,
        size: 10,
      },
      isOver: "加载中",
      boxDetail: {
        flag: false,
        boxId: "",
      },
    };
  },

  mounted() {
    this.getBox();
    this.getuser();
    /* if (this.boxTypeList.length == 0) {
      getBoxTypeList(this.typePage).then((res) => {
        if (res.data.code == 200) {
          this.boxTypeList.push(...res.data.data.records);
          if (res.data.data.records.length == this.typePage.size) {
            this.typePage.page++;
            this.getTypeList();
          } else {
            this.getAll = true;
          }
        }
        console.log("玩法列表", this.boxTypeList);
      });
    } */
  },
  computed: {
    totalround() {
      return this.obj.boxData.reduce((total, box) => total + box.boxNum, 0);
    },
    totalmoney() {
      let bean = 0;
      this.obj.boxData.forEach((item) => {
        bean += item.price * item.boxNum;
      });
      return bean.toFixed(2);
    },
  },
  methods: {
    getBoxDetail(boxId) {
      console.log(boxId);
      this.boxDetail.flag = true;
      this.boxDetail.boxId = boxId;
    },
    handleScroll(e) {
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >=
        e.srcElement.scrollHeight
      ) {
        if (this.isOver == "加载中") {
          this.boxPage.page++;
          setTimeout(() => {
            this.getBox();
          }, 5);
        }
      }
    },
    playAudio() {
      this.$store.commit("playAudio");
    },
    //设置房间人数
    selectpeople(res) {
      this.objj.playerNumber = res;
    },
    //获取用户信息
    getuser() {
      getUserInfo().then((res) => {
        if (res.data.code == 200) {
          this.userId = res.data.data.userId;
          console.log(this.userId);
        }
      });
    },
    //设置房间类型
    selectmodel(res) {
      this.objj.model = res;
      console.log(this.objj);
    },
    //箱子的类别

    //获取全部的箱子
    getBox() {
      let data={
        boxType:2
      }
      getBoxList(data).then((res) => {
        if (res.data.code == 200) {
          console.log("箱子列表", res.data.data);
          // this.boxTypelist = res.data.data;
          let arr = res.data.data;
          arr.map((item) => {
            return (item.boxNum = 0);
          });
          let arr2=[]
          arr.forEach((item) => {
            arr2.push(...item.boxList)
          })
          this.boxList = arr2
          this.boxList.map((item) => {
            return (item.boxNum = 0);
          });
          console.log(this.boxList)
          if (res.data.data.length < this.boxPage.size) {
            this.isOver = "暂无更多";
          }
        }
      });
    },

    /* 关闭 */
    handleclose() {
      const element = this.$refs.slider;
      element.classList += " animate__fadeOutDown";
      setTimeout(() => {
        this.boxList = [];
        this.changeboxList = [];
        this.boxTypelist = [];
        this.$bus.$emit("show_create_room", false);
        this.$router.back();
        this.obj = {
          boxData: [],
          createNum: 0,
          model: 0,
        };
        this.boxTypelist = [];
      }, 350);
    },
    //创建房间
    handleCreat() {
     /*  console.log(this.objj)
      createFight(this.objj).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "创建成功",
            customClass: "log_success",
          });
          this.$router.push({
            name: "battleroom",
            query: {
              id: res.data.data.id,
              uid: this.userId,
            },
          });
        } else {
          this.$message({
            type: "warning",
            message: res.data.msg,
            customClass: "log_warning",
          });
        }
      }); */
      if (this.objj.boxIdAndNumber) {
        const obj = Array.from(this.objj.boxIdAndNumber).reduce(
          (obj, [key, value]) => Object.assign(obj, { [key]: value }),
          {}
        );
        this.objj.boxIdAndNumber = obj;
        console.log("创建房间数据", this.objj);
        // 请求服务器创建对战
        createFight(this.objj).then((res) => {
          if (res.data.code == 200) {
            this.$bus.$emit("show_create_room", false);
            // this.$router.back()
            // this.handleclose();
            // 提示
            this.$message({
              type: "success",
              message: "创建成功",
              customClass: "log_success",
            });

            // 跳转
            // this.$router.back()
            setTimeout(() => {
              this.$router.replace({
                name: "battleroom",
                query: {
                  id: res.data.data.id,
                  uid: this.userId,
                },
              });
            }, 500);
          } else {
            console.log("api/fight/createFight 异常");
            this.$message({
              type: "warning",
              message: res.data.msg,
              customClass: "log_warning",
            });
          }
        });
      }

      /*  const round = this.obj.boxData.reduce(
        (total, box) => total + box.boxNum,
        0
      );
      this.obj.rounds = round;
      createFight(this.obj).then((res) => {
        console.log(res)
        if (res.data.code == 200)
          this.$message({
            type: "success",
            message: res.data.msg,
            customClass: "log_success",
          });
        this.handleclose();
        this.$router.push({
          name: "battleroom",
          query: {
            id: res.data.data.id,
          },
        });
      }, 199); */
    },
    handleDel(res) {
      console.log(res)
      if (res.boxNum <= 0) {
        return;
      } else {
        console.log("减", this.objj.boxIdAndNumber);
        if (!this.objj.boxIdAndNumber.set) {
          // console.log('不是map');
          let map = new Map();
          let key;
          for (key in this.objj.boxIdAndNumber) {
            map.set(key, this.objj.boxIdAndNumber[key]);
          }

          this.objj.boxIdAndNumber = map;
        }
        this.objj.boxIdAndNumber.set(res.boxId, res.boxNum - 1);
        // console.log(this.objj.boxIdAndNumber);
        this.$set(res, "boxNum", res.boxNum - 1);
        // console.log(res);
        // res.boxNum -= 1;
        this.$forceUpdate();
        /* map1.delete(); */
        this.obj.boxData.forEach((item) => {
          if (item.boxNum === 0) {
            let index = this.changeboxList.indexOf(item); // 获取元素在数组中的索引位置
            this.obj.boxData.splice(index, 1); // 从数组中移除该元素
          }

          /*  else {
        //     // this.$forceUpdate();
        //     // this.totalround = 0;
        //     // this.obj.boxData.forEach(item => {
        //     //   this.totalround += item.boxNum;
        //     // });
        //   } */
        });
      }
    },
    handlePush(res) {
      if (this.obj.boxData.length == 6 && res.boxNum == 0) {
        this.$message({
          type: "warning",
          message: "最多选择6种盲盒",
          customClass: "log_warning",
        });
        return false;
      }
      if (this.totalround < 12) {
        res.boxNum += 1;
        let index = this.changeboxList.findIndex(
          (item) => item.boxId === res.boxId
        );
        // 如果有就替换，没有就添加
        if (index !== -1) {
          this.changeboxList.splice(index, 1, res);
        } else {
          this.changeboxList.push(res);
        }
        let sum = 0;
        this.changeboxList.forEach((obj) => {
          sum += obj.boxNum; // 将每个对象的 value1 值相加到 sum 上
          this.money += obj.price;
        });
        /* console.log(this.changeboxList) */
        const map1 = new Map();
        this.changeboxList.forEach((v) => {
          map1.set(v.boxId, v.boxNum);
        });

        this.objj.boxIdAndNumber = map1;
        /*     console.log(this.objj.boxIdAndNumber) */

        /*  boxobj */
        this.obj.createNum = sum;
        this.obj.boxData = this.changeboxList;
      } else {
        this.$message({
          type: "warning",
          message: "选择数量超出最大限制",
          customClass: "log_warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.creat_bg {
  position: fixed;
  top: 0;
  left: 50%;
  // margin-left: -262.5px;
  width: 525px;
  transform: translateX(-50%);
  height: 100vh;
  z-index: 20;
  background: rgba(0, 0, 0, 0.85);
  padding: 2% 0;

  @media (max-width: 550px) {
    width: 100%;
  }

  .creat_close {
    width: 100%;
    text-align: right;
    position: relative;
    height: 25px;

    .icon {
      position: absolute;
      right: 10px;
      margin: 0;
      width: 22px;
      height: 22px;

      @media (max-width: 550px) {
        width: 16px;
        height: 16px;
      }
    }
  }

  .fjrs {
    @media (max-width: 550px) {
      font-size: 15px;
    }
  }

  .creat_number {
    width: 93.5%;
    margin: 0 auto;
    text-align: left;

    .creat_number_select {
      width: 57%;
      height: 42px;
      margin-top: 1%;
      background: #323436;
      border-radius: 70px 70px 70px 70px;
      opacity: 1;
      color: #727477;
      font-size: 14px;

      @media (max-width: 550px) {
        width: 215px;
        height: 30px;
        font-size: 10px;
      }

      .active {
        background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
        color: #fff;
      }

      .select_btn {
        width: 32%;
        height: 35px;
        border-radius: 70px;

        @media (max-width: 550px) {
          width: 70px;
          height: 25px;
        }
      }
    }
  }

  .creat_type {
    width: 93.5%;
    margin: 0 auto;
    margin-top: 1%;

    .create_type_home {
      width: 40%;

      @media (max-width: 550px) {
        font-size: 15px;
      }

      .create_type_model {
        margin-top: 1%;
        width: 100%;
        height: 43px;
        background: #323436;
        border-radius: 70px 70px 70px 70px;
        opacity: 1;
        color: #727477;

        @media (max-width: 550px) {
          width: 145px;
          height: 30px;
          font-size: 10px;
        }

        .select_btn {
          width: 48%;
          height: 35px;
          border-radius: 70px 70px 70px 70px;
          opacity: 1;

          @media (max-width: 550px) {
            width: 70px;
            height: 25px;
          }
        }

        .active {
          background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
          color: #fff;
        }
      }
    }

    .box_type {
      width: 140px;
      height: 42px;
      background: #323436;
      border-radius: 70px 70px 70px 70px;
      opacity: 1;
      position: relative;

      @media (max-width: 550px) {
        width: 100px;
        height: 30px;
        font-size: 11px;
      }

      .box_type_low {
        position: absolute;
        top: 45px;
        width: 100%;
        background: #323436;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        animation: xiala 0.3s linear;
        overflow: hidden;

        @media (max-width: 550px) {
          top: 35px;
          // height: 30px;
        }

        @keyframes xiala {
          0% {
            height: 0;
          }

          50% {
            height: 50%;
          }

          100% {
            height: 100%;
          }
        }

        .active {
          background: linear-gradient(360deg,
              rgba(37, 244, 132, 0.5) 0%,
              rgba(37, 244, 132, 0.25) 100%);
          opacity: 1;
          border: 1px solid #25f484;
        }

        .type_btn {
          width: 100%;
          height: 42px;

          @media (max-width: 550px) {
            height: 30px;
          }
        }
      }
    }
  }

  .creat_box {
    width: 93.5%;
    height: 98px;
    background: #101115;
    border-radius: 14px 14px 14px 14px;
    opacity: 1;
    margin: 0 auto;
    margin-top: 1%;

    @media (max-width: 550px) {
      height: 70px;
    }

    .center_img {
      width: 70px;
      margin-right: 28px;

      position: relative;

      @media (max-width: 550px) {
        width: 50px;
        margin-right: 20px;
      }
    }

    .center_number {
      width: 28px;
      height: 28px;
      position: absolute;
      right: -11px;
      top: 50%;
      transform: translateY(-50%);
      background: #25f484;
      border-radius: 50%;

      @media (max-width: 550px) {
        font-size: 13px;
        width: 21px;
        height: 21px;
      }
    }
  }

  .creat_msg {
    margin: 0 auto;
    margin-top: 14px;
    width: 93.5%;
    height: 98px;
    background: #101115;
    border-radius: 14px;
    font-size: 18px;
    line-height: 2;

    @media (max-width: 550px) {
      font-size: 13px;
      height: 70px;
    }
  }

  .creat_boxlist {
    width: 93.5%;
    margin: 0 auto;
    padding: 14px 32px;
    height: 434px;
    background-color: #101115;
    border-radius: 14px;
    margin-top: 14px;
    overflow-y: scroll;
    scrollbar-width: none;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 14px;

    @media (max-width: 550px) {
      padding: 10px 13px;
      height: 360px;
    }

    @media (max-width: 375px) {
      height: 270px;
    }

    .active {
      border: 1px solid #25f484 !important;
    }

    .boxlist_box {
      width: 100%;
      height: 280px;
      margin: 0 auto;
      padding: 6% 0;
      border: 1px solid #727477;
      border-radius: 14px;
      transition: all 0.3s ease;
      background-color: rgba(114, 116, 119, 0.1);

      @media (max-width: 550px) {
        height: 200px;
      }
    }

    .boxlist_img {
      width: 128px;
      height: 128px;
      margin: 0 auto;

      @media (max-width: 550px) {
        width: 91px;
        height: 91px;
      }
    }

    ::v-deep .boxlist_number {
      position: relative;
      margin: 0 auto;
      margin-top: 5%;
      width: 140px;
      font-size: 22px;
      height: 38px;

      @media (max-width: 550px) {
        width: 100px;
        height: 27px;
      }

      .boxlist_input {
        position: absolute;
        top: 0px;
        left: 50%;
        height: 38px;

        width: 100%;
        transform: translateX(-50%);

        @media (max-width: 550px) {
          height: 27px;
        }
      }

      .input_number {
        @media (max-width: 550px) {
          width: 41px;
          font-size: 16px;
        }
      }

      .jian {
        width: 39px;
        height: 39px;

        @media (max-width: 550px) {
          width: 27px;
          height: 27px;
        }
      }

      .jia {
        width: 39px;
        height: 39px;

        @media (max-width: 550px) {
          width: 27px;
          height: 27px;
        }
      }

      .actives {
        svg {
          path {
            fill: #25f484 !important;
          }
        }
      }
    }

    .boxlist_money {
      margin-top: 5%;

      @media (max-width: 550px) {
        font-size: 10px;
      }
    }

    .center_boxboxlist_name {
      margin-top: 5%;

      @media (max-width: 550px) {
        font-size: 16px;
      }
    }
  }

  .creat_boxlist::-webkit-scrollbar {
    background: transparent;
    height: 7px;
  }

  .creat_boxlist::-webkit-scrollbar-thumb {
    width: 7px;
    background: #323436;
    opacity: 1;
    border-radius: 7px;
  }

  .cerate_btn {
    width: 93.5%;
    height: 56px;
    margin: 0 auto;
    margin-top: 14px;
    background: linear-gradient(360deg,
        rgba(37, 244, 132, 0.2) 0%,
        rgba(37, 244, 132, 0.1) 100%);
    opacity: 1;
    border: 1px solid #25f484;
    font-size: 25px;

    @media (max-width: 550px) {
      font-size: 18px;
      height: 40px;
    }
  }
}
</style>
